import React,{useState} from 'react'
import { Formik } from 'formik';
import swal from 'sweetalert';
import { Link } from 'react-router-dom'
const Footer = () => {
  const [isLoading, setLoading] = useState(false)
  const formSubmit = async (values, {resetForm}) =>{
    setLoading(true)
    //https://maxapi.articloo.com
    const rawResponse = await fetch('https://mxapi.maxxwellness.in/api/booking/submit', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(values)
    });
    const res = await rawResponse.json();
   if(res && res.status === true){
    setLoading(false)
    resetForm();
    swal("Thank you !", res.message, "success");
   }else{
    setLoading(false)
    resetForm();
    swal("Sorry !", "Something went wrong !", "error");
   }
  }
  return (
    <footer >
    <div className='container'>
      <div className='footer-top'>
        <div className='row'>
          <div className='col-6 col-sm-12'>
            <div className='footer-brand'>
              <img src={process.env.PUBLIC_URL + '/images/logo.png'} alt="logo" />
              <div className='brand-content'>
                <p>We help you <br /> and your family <br /> stay fit, fun <br /> and happy
                </p>
              </div>
            </div>
          </div>
           {/* <iframe width='100%' height='480px' src='https://bigin.zoho.in/crm/WebFormServeServlet?rid=80677d7165b195c02387db8e37ea03fc37d534aaddea34a65d18af3ad03ff6d4gid71ea9139e1fbbeb931227dbcd956ad07cfb97d82a3217b23c4a2ebb30338ac2a'></iframe> */}
          <div className='col-6 col-sm-12'>
            <div className='query-form'>
              <div className='form-title'>
                <p>Get Free Trial</p>
              </div>

              <Formik
                  initialValues={{ name: '', mobile_number:'', email: ''}}
                  validate={values => {
                    const errors = {};
                    if (!values.name) {
                      errors.name = 'Name is required !';
                    }
                    if (!values.mobile_number) {
                      errors.mobile_number = 'Mobile Number is required !';
                    }
                    if (!values.email) {
                      errors.email = 'Email is required !';
                    } else if (
                      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                    ) {
                      errors.email = 'Invalid email address';
                    }
                    return errors;
                  }}
                  onSubmit={formSubmit}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    /* and other goodies */
                  }) => (
                    <form onSubmit={handleSubmit}>
          <div className="form-group">
            <input type="text" 
            className="form-control" 
            placeholder="Name" 
            name="name"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.name}
            />
            {errors.name && touched.name && <span className='help-error'>{errors.name}</span>}
          </div>
        
          <div className="form-group">
            <input type="text" 
            className="form-control" 
            placeholder="Mobile Number" 
            name="mobile_number"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.mobile_number}
            />
            {errors.mobile_number && touched.mobile_number && <span className='help-error'>{errors.mobile_number}</span>}
          </div>
          <div className="form-group">
            <input type="text" 
            className="form-control" 
            placeholder="Email Address" 
            name="email"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
            />
            {errors.email && touched.email && <span className='help-error'>{errors.email}</span>}
          </div>
          <div className="text-center">
            <button type="submit" className="btn btn-primary">{isLoading ? 'Please wait..' : 'Book Now'}</button>
          </div>
        </form>)}
                </Formik>
            </div>
          </div>
        </div>


      </div>
      <div className='footer-bottom'>
        <div className='row'>
          <div className='col-3 col-sm-6 col-xs-12 pd20'>
            <div className='footer-box'>
            <div className='footer-title'>
              <p>Contact</p>
            </div>
            <p>
              Maxx Prime LLP 2nd Floor, Good wood
              building, Patanwala
              estate, LBS marg,
              Ghatkopar (west),
              Mumbai - 400086</p>
            <p className='mt-20'>support@maxxwellness.in </p>
            </div>
            
          </div>
          <div className='col-3 col-sm-6 col-xs-12 pd20'>
          <div className='footer-box'>
            <div className='footer-title'>
              <p>Know more on</p>
            </div>
            <ul className='menu-list'>
              <li><Link to="/">Super Kid</Link></li>
              <li><Link to="/">Make Me Fit</Link></li>
              <li><Link to="/">Super Mom</Link></li>
              <li><Link to="/blog">Maxx Blogs</Link></li>
            </ul>
            </div>
          </div>
          <div className='col-3 col-sm-6 col-xs-12 pd20'>
          <div className='footer-box'>
            <div className='footer-title'>
              <p>Latest News</p>
            </div>
            <p>
              <p className='txt-gr'>March 10, 2022</p>
              <p>How to lose fat in
                5 weeks at home</p>
              <p className='txt-gr mt-10'>March 05, 2022</p>
              <p>Managing Family and
                Nourishing Self</p>
            </p>
            </div>
          </div>
          <div className='col-3 col-sm-6 col-xs-12 pd20'>
          <div className='footer-box'>
            <div className='footer-title'>
              <p>Stay in touch</p>
            </div>
            <ul className='social-links'>
              <li><Link to="/"><i className='i-instagram'></i></Link></li>
              <li><Link to="/"><i className='i-facebook'></i></Link></li>
              <li><Link to="/"><i className='i-twitter'></i></Link></li>
            </ul>
          </div>
          </div>
        </div>
      </div>
    </div>
    {/* <div className='footer-arrow'>
  <img src={process.env.PUBLIC_URL + '/images/bottom-icon.png'} alt="bottom-arrow" />
  </div> */}
<div className='whatsApp-link'>
  <a href='https://api.whatsapp.com/send/?phone=918657604581&text&app_absent=0' target="_blank" rel="noreferrer">
  <i className='i-whatsapp'/>
  </a>
 
</div>

  </footer>
  )
}

export default Footer