import React, { useState } from 'react'
import { Formik } from 'formik';
import swal from 'sweetalert';
const Contact = () => {
const [isLoading, setLoading] = useState(false)
  const formSubmit = async (values, {resetForm}) =>{
    setLoading(true)
    const rawResponse = await fetch('https://mxapi.maxxwellness.in/api/contact/submit', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(values)
    });
    const res = await rawResponse.json();
   if(res && res.status === true){
    setLoading(false)
    resetForm();
    swal("Thank you !", res.message, "success");
   }else{
    setLoading(false)
    resetForm();
    swal("Sorry !", "Something went wrong !", "error");
   }  
  }
  return (
    <div className='inner-wrapper'>
      <div className='inner-banner'>
        <div className='container'>
          <div className='sub-title'>
            <p> <span className='bd-left'></span>HERE’S HOW YOU CAN GET IN TOUCH</p>
          </div>
          <div className='title'>
            <h1>CONTACT US</h1>
          </div>
        </div>
      </div>
      <div className='inner-page-content'>
        <div className='container'>
          <div className='row'>
            <div className='col-7 col-md-12'>
              <div className='contact-form'>
                <p>Please drop your details below and one of the team will get in touch.</p>
                <Formik
                  initialValues={{ fname: '', lname: '', email: '', phone: '', query: '' }}
                  validate={values => {
                    const errors = {};
                    if (!values.fname) {
                      errors.fname = 'First name is required !';
                    }
                    if (!values.lname) {
                      errors.lname = 'Last name is required !';
                    }
                    if (!values.email) {
                      errors.email = 'Email is required !';
                    } else if (
                      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                    ) {
                      errors.email = 'Invalid email address';
                    }
                    if (!values.phone) {
                      errors.phone = 'Phone is required !';
                    }
                    if (!values.query) {
                      errors.query = 'Query is required !';
                    }

                    return errors;
                  }}
                  onSubmit={formSubmit}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    /* and other goodies */
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <div className='form-group row'>
                        <div className='col-6 br-0 pd-0'>
                        <input type="text"
                          className='form-control pd-15'
                          name="fname"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.fname}
                          placeholder='First Name'
                        />
                        {errors.fname && touched.fname && <span className='help-error'>{errors.fname}</span>}
                        </div>
                        <div className='col-6 pd-0 '>
                        <input type="text"
                          className='form-control pd-15'
                          name="lname"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.lname}
                          placeholder='Last Name'
                        />
                        {errors.lname && touched.lname && <span className='help-error'>{errors.lname}</span>}
                          </div>
                        
                      
                      </div>
                     
                      <div className='form-group row'>
                        <div className='col-12 pd-0'>
                        <input type="text"
                          className='form-control pd-15'
                          name="email"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email}
                          placeholder='Email'
                        />
                        {errors.email && touched.email && <span className='help-error'>{errors.email}</span>}
                        </div>
                       
                      </div>
                      <div className='form-group row'>
                      <div className='col-12 pd-0'>
                        <input type="text"
                          className='form-control pd-15'
                          name="phone"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.phone}
                          placeholder='Phone Number'
                        />
                         {errors.phone && touched.phone && <span className='help-error'>{errors.phone}</span>}
                        </div>
                      </div>
                      <div className='form-group row'>
                        <label className='pd0'>How can we help?</label>
                        <div className='col-12 pd-0'>
                        <textarea
                          name="query"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.query}
                          className='form-control pd-15'
                        ></textarea>
                         {errors.query && touched.query && <span className='help-error'>{errors.query}</span>}
                        </div>
                      </div>
                      <button type='submit' className='btn btn-block btn-dark'>{isLoading ? 'Please wait..':'Send Message'}</button>
                    </form>)}
                </Formik>
              </div>
            </div>
            <div className='col-5 col-md-12'>
              <div className='ct-img'>
                <img src={process.env.PUBLIC_URL + "/images/contact/Picture.png"} alt="kid" className='img-resp img-wm' />
              </div>
            </div>

          </div>

          <div className='map-section'>
            <div className='map-title'>
              <h4>Planning to visit our office? Locate us</h4>
            </div>
            <div className='map-content'>
              <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d120624.85631689262!2d72.9252541!3d19.128406!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x3e06f6937200e3c6!2sMaxx%20Wellness!5e0!3m2!1sen!2sin!4v1650278116649!5m2!1sen!2sin" width="100%" height={350} frameBorder={0} style={{ border: 0 }}  ></iframe>


            </div>
          </div>
        </div>
      </div>
      <div className='kid_section'>
        <div className='container'>
          <div className='kid_image'>
            <img src={process.env.PUBLIC_URL + "/images/kid.png"} alt="kid" className='img-resp' />
          </div>
        </div>

      </div>
    </div>

  )
}

export default Contact