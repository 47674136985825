import React, { useState } from 'react'
import { Link } from 'react-router-dom';

const FeaturedPostSlider = ({ items }) => {
  const [current, setCurrent] = useState(0);
  const length = items.length;
  const nextSlide = () => {
    setCurrent(current === length -1 ? 0 : current+1);
  }

  const prevSlide = () => {
    setCurrent(current === 0 ? length -1 : current-1);
  }
  if (!Array.isArray(items) || items.length <= 0) {
    return null;
  }
  return (
    <section className='slider'>
      
      {
        items?.map((item, i) => {
          return <div key={i} className={i === current ? 'slide active' : 'slide'}>
            {
              i === current && (
                  <div className='featured-item'>
                  <div className='row'>
                      <div className='col-8 col-md-12'>
                       <img src={item.thumb_image} alt="img1" className='image' />
                      </div>
                      <div className='col-4 col-md-12'>
                      <div className='post-content'>
                      <p className='fPost-subtitle'>Featured Post</p>
                      <h1 className='fPost-title'>{item.title}</h1>
                      <p className='auther_name'> {item.auther}</p>
                      <p className='date'>{item.date}</p>
                      <div className='devider'></div>
                      <div className='excerpt'>
                         <p >{item.excerpt}</p>
                      </div>
                     
                      <Link to="#" className='read-more'> Read More <img src="/images/blog/right-ar.png" alt="img1" className='image' /></Link>
                      </div>
                     
                      </div>
                  </div>
                  
                 
                  </div>
                 
                  )
            }
            
            </div>
        })
      }
      <div className='slider-control'>
      
      <button className='icon-slider mr-0 prev' onClick={prevSlide}><img src="/images/blog/back.png" alt="img1" className='image' /> Previous</button>
      <button className='icon-slider next' onClick={nextSlide}>  Next<img src="/images/blog/next.png" alt="img1" className='image' /></button>
      </div>
     
    </section>
  )
}

export default FeaturedPostSlider