import React,{useRef, useState} from 'react'
import { Link } from 'react-router-dom'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
const serviceData = [
  {
   title:"Family Plans",
   thumbnail:"/images/sr-1.png"
  },
  {
    title:"Couple Plans",
    thumbnail:"/images/sr-1.png"
   },
   {
    title:"Individual Plans",
    thumbnail:"/images/sr-1.png"
   },
   {
    title:"Corporate Plans",
    thumbnail:"/images/sr-1.png"
   }
]
const Home = () => {
  const myRef = useRef(null)
  const executeScroll = () => myRef.current.scrollIntoView()   
  const [isSelectedService, setSelectedService] = useState(0)
  return (
    <>
    <div className='pd76'>
      <div className='main-banner'>
        <div className='left-menu'>
          <ul>
            <li><Link to="/">Fun</Link></li>
            <li><Link to="/">Fitness</Link></li>
            <li><Link to="/">Family Time</Link></li>
          </ul>
        </div>
        <div className='right-arrow'>
          <img src={process.env.PUBLIC_URL + '/images/bottom-icon.png'} alt="bottom-arrow" />
        </div>
        <div className='container'>
          <Carousel showThumbs={false} showArrows={false} showStatus={false} autoFocus={true}>
            <div>
              <img src={process.env.PUBLIC_URL + '/images/slider-1.jpg'} alt='slide' />
              <div className="legend">
                <h1>Making Personalised <span>wellness</span> more accessible</h1>
                <Link to="#" className='signUpBtn' onClick={executeScroll}>Sign up here</Link>
              </div>
            </div>
            <div>
              <img src={process.env.PUBLIC_URL + '/images/slider-2.jpg'} alt='slide' />
              <div className="legend">
                <h1>get yourself <span>Fun & Fitness </span> all in one platform</h1>
                <Link to="#" className='signUpBtn' onClick={executeScroll}>Sign up here</Link>
              </div>


            </div>
            <div>
              <img src={process.env.PUBLIC_URL + '/images/slider-3.jpg'} alt='slide' />
              <div className="legend">
                <h1>when  training & <span>family time </span>becomes one </h1>
                <Link to="#" className='signUpBtn' onClick={executeScroll}>Sign up here</Link>
              </div>  
            </div>

          </Carousel>
        </div>
      </div>
      <div className='homeAbout'>
        <div className='container'>
          <div className='homeAboutContent'>
            <div className='top-heading'>
              <p><span></span > Everything you need</p>
            </div>
            <div className='bottom-content'>
              <h1><span>Maxx wellness</span> is a <br /> one-stop platform for Holistic development for your entire family</h1>
              <p>We provide life transforming fitness programs for everyone</p>
            </div>

          </div>
          <div className='team-img'>
            <img src={process.env.PUBLIC_URL + "/images/girl-image.png"} alt=""/>
          </div>
          {/* <div className='globe-img'>
            <img src={process.env.PUBLIC_URL + "/images/globe.png"} alt=""/>
          </div> */}
        </div>
      </div>
      <div className='homeService'>
        <div className='container'>
          <div className='homeServiceInner'>
            <div className='row'>
              {
                serviceData.map((item, i)=>{
                  return <div className='col-3 col-sm-6 col-xs-12' key={i}>
                  <div className={isSelectedService === i ? 'service-box acitve' : 'service-box' } onClick={()=>setSelectedService(i)}>
                    <Link to="#">
                      <img src={process.env.PUBLIC_URL + '/images/sr-1.png'} alt="ser1" />
                      <p className='service-title'>{item.title}</p>
                      <span className='border-line'></span>
                    </Link>
                  </div>
                </div>
                })
              }
             
             
            </div>
          </div>
        </div>
      </div>
      <div className='homeWhyWeAre'>
        <div className='container'>
          <div className='we-title'>
            <h1>Why are we <span>Different?</span> </h1>
          </div>
          <div className='we-inner'>
            <div className='row item-center'>
              <div className='col-6 col-sm-12'>
                <div className='image-wrapper'>
                  <img src={process.env.PUBLIC_URL + '/images/we-1.png'} alt="we-1"  className='img-resp'/>
                </div>
              </div>
              <div className='col-6 col-sm-12'>
                <div className='wecontent-wrapper bd1'>
                  <div className='we-listitem'>
                    <h1>Twelve+</h1>
                    <p>Years of experience</p>
                  </div>
                  <div className='we-listitem'>
                    <h1>1 Lakh+</h1>
                    <p>Participants trained from <br /> different age groups till date </p>
                  </div>
                  <div className='we-listitem'>
                    <h1>Periodic</h1>
                    <p>Assessments</p>
                  </div>
                </div>
              </div>
            </div>
            <div className='row item-center pt-30'>
              <div className='col-6 col-sm-12'>
                <div className='wecontent-wrapper'>
                  <div className='we-listitem'>
                    <h1>200+</h1>
                    <p>Coaches</p>
                  </div>
                  <div className='we-listitem'>
                    <h1>Certified</h1>
                    <p>Coaches with rich <br /> domain experience</p>
                  </div>
                  <div className='we-listitem'>
                    <h1>Exposure</h1>
                    <p>To international <br /> events/competitions</p>
                  </div>
                </div>
              </div>
              <div className='col-6 col-sm-12 '>
                <div className='image-wrapper bd1 pd10'>
                  <img src={process.env.PUBLIC_URL + '/images/we-2.png'} alt="we-1" className='img-resp'/>
                </div>

              </div>
            </div>
            <div className='row item-center pt-30'>
              <div className='col-6 col-sm-12'>
                <div className='image-wrapper '>
                  <img src={process.env.PUBLIC_URL + '/images/we-3.png'} alt="we-1" className='img-resp'/>
                </div>
              </div>
              <div className='col-6 col-sm-12'>
                <div className='wecontent-wrapper'>
                  <div className='we-listitem'>
                    <h1>Global Standards</h1>
                    <p>Uniquely designed <br /> program modules as per</p>

                  </div>
                  <div className='we-listitem'>
                    <h1>Personalized</h1>
                    <p>A personalised learning <br /> experience for every kid</p>
                  </div>
                </div>
              </div>
            </div>
          </div>


        </div>
      </div>
      <div ref={myRef} className='kid_section'>
        <div className='container'>
          <div className='kid_image'>
            <img src={process.env.PUBLIC_URL + "/images/kid.png"} alt="kid" className='img-resp'/>
          </div>
        </div>

      </div>

    </div>
      
    </>
    
    
  )
}

export default Home



























