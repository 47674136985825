import { Fragment } from 'react'
import Header from './components/Header'
import Footer from './components/Footer'
import Home from './pages/Home';
import Contact from './pages/Contact'
import About from './pages/About'
import Blog from './pages/blog/Blog'
import ComingSoon from './pages/ComingSoon'
import ScrollToTop from './components/ScrollToTop';
import { Switch, Route } from "react-router-dom";

function App() {
  return (
    <Fragment>
      <Header />
      <ScrollToTop />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/contact" component={Contact} />
        <Route exact path="/about" component={About} />
        <Route exact path="/blog" component={Blog} />
        <Route exact path="/coming-soon" component={ComingSoon} />
      </Switch>
      <Footer />
    </Fragment>
  );
}

export default App;
